@import "../../styles/main.scss";

ion-modal#editSub-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

// ion-modal#editSub-modal::part(backdrop) {
//   // background: #000;
//   opacity: 1;
// }

ion-modal#editSub-modal ion-toolbar,
ion-modal#createSub-modal ion-toolbar {
  // --background: #b7c3cf;
  --background: #1e3176;
  //--color: rgb(69, 69, 69);
  --color: lightgrey;
  border: 1px solid rgb(190, 183, 183, 0.5);
}

.editor .modal-wrapper {
  background-color: rgb(178, 181, 226);
  overflow: hidden;
}

.editor-container {
  display: flex;
  flex-direction: row;
}

.custom-time {
  display: flex;
  padding: 5px;
  margin: 5px;
}

.custom-textarea {
  border: 1px solid rgb(190, 183, 183, 0.5);
  /*  height: 33%; 
    max-height: 33%; */
  overflow-y: auto;
  //background-color: rgb(48, 48, 69);
  font-size: 1.3rem;
  --background: #373737;
  //--color: #fff;
  --color: #b5c7d9;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
}

.subtitle-content {
  display: flex;
  flex-grow: 1;
  min-height: 200px;
}

// .button-group {
//   margin-top: 1rem;
//   margin-right: 1rem;
//   display: flex;
//   justify-content: flex-end; /* Align items to the right */
//   gap: 0.5rem; /* Space between buttons, you can adjust as needed */
//   height: 40px;
// }
.button-group {
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between; /* Align items to the right */
  gap: 0.5rem; /* Space between buttons, you can adjust as needed */
  height: 40px;
}
.button-group .left-buttons,
.button-group .right-buttons {
  display: flex;
  gap: 0.5rem; /* Space between buttons within each group */
}

.button-group > ion-button {
  min-width: 15%; /* Width of each button */
}
