.mainSearch {
  display: flex;

  ion-searchbar {
    margin-top: 0.2rem;
    padding: 0 0.2rem;
    max-width: 83%;
  }

  ion-fab-button {
    margin-left: auto;
    margin-right: 1rem;
  }
}
