@import "../styles/main.scss";

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__leftContent {
    display: flex;
    align-items: center; // This ensures vertical alignment of all items within the flex container

    .controls__fabWrapper {
      display: inline-flex; // Allows internal items to flex
      align-items: center; // Ensures vertical alignment of the fab button
      margin-right: 0.1rem; // Space between the fab and the next button
    }

    .controls__fab {
      position: static; // Overrides any absolute positioning set by IonFab
    }

    .ion-button {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .active-button {
      border: 2px solid #ff0000cc !important; // Use !important to override Ionic styles if necessary
      border-radius: 12px;
    }
  }

  &__mainContent {
    display: flex;
    align-items: center;

    .ion-button {
      margin-left: 0.5rem; // Adjust for space between regular buttons
      margin-right: 0.5rem;
    }

    .playback-button {
      flex: 1; // This will ensure that each button takes equal width by default

      @media (min-width: $bp-tablet-portrait) {
        &:nth-child(2) {
          // Targeting the middle button, which is the play button
          flex: 3; // Makes it double the size of the other buttons
        }
      }

      @media (min-width: $bp-desktop) {
        &:nth-child(2) {
          flex: 3; // Makes it three times the size of the other buttons
        }
      }
    }
  }
}

/*
.controls {
  display: flex;
  align-items: center;

  &__leftContent {
    display: flex;    

    &__left {
      flex-basis: 25%;
      flex-grow: 0;

      @media only screen and (min-width: $bp-tablet-portrait) {        
        flex-basis: 15%;
      }
    }
    &__right {
      display: flex;
    }
  }
}
*/
