
.playerSubsContainer {
  width: 60vw;
  // padding-left: 1.3rem;
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
}

.subcontent-top {
  color: rgb(86,194,253);
  border-radius: 1px;
  padding: 1px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border: none;
  overflow: auto;

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 576px) {
  .subcontent-top {
    height: 2.8rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .subcontent-top {
    height: 1.6rem;
  }
}

.small {
  /* Apply desired styles when the accordion is open */
  font-size: 75%;
  --min-height: 1px;
  display: table-cell;
  vertical-align: top;
  margin-bottom: 1px;
}

.subcontent-bottom {
  padding-top: 1px;
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 2px;
}

.custom-label {
  --min-height: 0rem !important;
  height: 1rem !important;
  margin-bottom: 0 !important;
  padding-bottom: 0.3rem !important;  
}

.custom-label .sc-ion-label-md-h,
.custom-label .sc-ion-label-md {
  height: 1rem !important;
  min-height: 1rem !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.custom-div {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.locked-label {
  --padding-start: 8px;
  --padding-end: 5px;
  --padding-top: 0;
  --padding-bottom: 0;
  --min-height: 1rem;
}

ion-popover {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 300px;
}

ion-popover ion-content {
  --background: rgba(40, 173, 218, 0.6);
}

.popover-label {
  font-size: 1.6rem;
  font-weight: bold;
}

.popoverOrig-label {
  font-size: 1.3rem;
  font-weight: bold;
}


@keyframes gradient-animation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@keyframes pulsing-animation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.gradient-text {
  color: #064076;
  animation: pulsing-animation 2s infinite;
}

.gradient-text-lower {
  color: #D4AC0D;
  animation: pulsing-animation 2s infinite;
}


// JMR
// .playerSubsContainer {
//   width: 60vw;
//   // padding-left: 1.3rem;
//   // border: 1px solid red;
//   // display: flex;
//   // flex-direction: column;
// }

// .subcontent-top {
//   color: lightgray;
//   border-radius: 1px;
//   padding: 1px;
//   white-space: pre-wrap;
//   overflow-wrap: break-word;
//   border: none;
//   overflow: auto;
// }

// /* Small devices (landscape phones, 576px and up) */
// @media (min-width: 0px) and (max-width: 576px) {
//   .subcontent-top {
//     height: 2.8rem;
//   }
// }

// /* Medium devices (tablets, 768px and up) */
// @media (min-width: 768px) {
//   .subcontent-top {
//     height: 1.6rem;
//   }
// }

// .small {
//   /* Apply desired styles when the accordion is open */
//   font-size: 75%;
//   --min-height: 1px;
//   display: table-cell;
//   vertical-align: top;
//   margin-bottom: 1px;
// }

// .subcontent-bottom {
//   padding-top: 1px;
//   padding-left: 1px;
//   padding-right: 1px;
//   padding-bottom: 2px;
// }

// .custom-label {
//   --min-height: 0rem;

//   &__title {
//     display: flex;
//     align-items: center;

//     span {
//       font-size: 0.9rem;
//       margin-top: -0.1rem;
//     }
//   }
// }

// .custom-label .sc-ion-label-md-h,
// .custom-label .sc-ion-label-md {
//   height: 1rem !important;
//   min-height: 1rem !important;
//   margin-bottom: 0 !important;
//   padding-bottom: 0 !important;
// }

// .custom-div {
//   margin-top: 0.4rem;
//   // margin-left: 1.3rem;
// }

// .locked-label {
//   --padding-end: .8rem;
// }

// ion-popover {
//   --background: rgba(40, 173, 218, 0.6);
//   --backdrop-opacity: 0.6;
//   --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
//   --color: white;
//   --width: 300px;
// }

// ion-popover ion-content {
//   --background: rgba(40, 173, 218, 0.6);
// }

// .popover-label {
//   font-size: 1.6rem;
//   font-weight: bold;
// }

// .popoverOrig-label {
//   font-size: 1.3rem;
//   font-weight: bold;
// }

// @keyframes gradient-animation {
//   0% {
//     background-position: 100% 0;
//   }
//   100% {
//     background-position: -100% 0;
//   }
// }

// @keyframes pulsing-animation {
//   0% {
//     opacity: 0.7;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0.7;
//   }
// }

// .gradient-text {
//   color: #064076;
//   animation: pulsing-animation 2s infinite;
// }

// .gradient-text-lower {
//   color: #d4ac0d;
//   animation: pulsing-animation 2s infinite;
// }


/*
.gradient-text {
  background: linear-gradient(45deg, #064076, lightgrey, #0385ff);
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  animation: gradient-animation 2s infinite;
}

.gradient-text-lower {
  background: linear-gradient(45deg, #D4AC0D, white, #4f3e00);
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  animation: gradient-animation 2s infinite;
}
*/
