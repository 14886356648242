
.todo-item {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 8px;
    padding: 5px;
    background: linear-gradient(to bottom right, #054d95, #263d4b);
    border-radius: 4px;
}

.todo-item input[type="checkbox"] {
    margin-right: 8px; /* Space between checkbox and text */
}

.todo-item p {
    color: #cccccc;
    text-decoration: none; /* Ensure text-decoration is not applied universally */
    flex-grow: 1; /* Allow text to take available space */
}

.todo-item p.completed {
    text-decoration: line-through;
}

button {
    margin-left: 8px; /* Consistent spacing between buttons */
}

button:hover {
    background-color: #e0e0e0; /* Hover effect for better user feedback */
}
ion-content {
    padding: 15px; // Adds padding around the content within the ion-content
  }

//   .todo-text {
//     overflow-y: auto; /* Enable vertical scrolling */
//     text-overflow: ellipsis;
//     display: block;
//     height: 1.8rem;
//     line-height: 1.3rem;
//     word-wrap: break-word; /* Allow long words to be broken and wrapped to the next line */
//     word-break: break-all; /* Break at any character to prevent overflow */
// }

.todo-text {
    word-wrap: break-word; /* Allow long words to be broken and wrapped to the next line */
    word-break: break-all; /* Break at any character to prevent overflow */
}

.completed {
    text-decoration: line-through;
}