.auth-text {
    opacity: 1000;
    color: #d3e3fd; 
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: #fff 0.006em 0.006em 0.007em, #787878 1px 1px 1px,
    #787878 1px 2px 1px, #787878 1px 3px 1px, rgba(16, 16, 16, 0.4) 1px 18px 6px,
    rgba(16, 16, 16, 0.4) 1px 22px 10px, rgba(16, 16, 16, 0.4) 1px 26px 35px,



}

.page {
    --ion-background-color: none; // Removes the default background
    background: linear-gradient(135deg, #00254d, #140d36, #0037dc);

}

.field-spacing {
    margin-bottom: 10px; // Adjust the space as needed
  }

.custom-toolbar {
    --background: linear-gradient(135deg, #00254d, #140d36, #210001);
  }
  
.custom-field {
    --background:  #001d3c;
  }
  