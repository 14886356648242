$lighter-color: #336699;
$base-color: #1a3544;
$darker-color: #000000;
$text-color: rgb(228, 228, 228);
$box-color: #ebf0f5;

.splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
 
    z-index: 100;
    perspective: 1500px;
  
    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .content-with-gradient {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        background: linear-gradient(to bottom, 
        rgba($lighter-color, 0.7), 
        rgba($base-color, 0.85), 
        rgba($darker-color, 0.95)
        );
        z-index: 1; // Making sure this content is above the video
      }

  }



.splash-text {
    transform: translateY(-100%) scale(1.3);
    opacity: 0;
    animation: flyIn 1s 0.7s forwards;
    color: $text-color;
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 20px; // Added to give space between text and boxes
    text-align: center;
    //text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    text-shadow: #fff 0.006em 0.006em 0.007em,
    #787878 1px 1px 1px,
    #787878 1px 2px 1px,
    #787878 1px 3px 1px,

    rgba(16,16,16,0.4) 1px 18px 6px,
    rgba(16,16,16,0.4) 1px 22px 10px,
    rgba(16,16,16,0.4) 1px 26px 35px,
    rgba(16,16,16,0.4) 1px 30px 65px,
    #fff -0.5em -0.1em 100px
  }

  
@keyframes flyIn {
    0% {
      transform: perspective(500px) translateY(-200%) scale(2);
      opacity: 0;
    }
    100% {
      transform: perspective(500px) translateY(0) scale(1);
      opacity: 1;
    }
  }

  


.box {
  width: 100px;
  height: 60px;
  background-color: $box-color;
  margin: 10px;  // Adjusted for better visual spacing
  transform: rotateY(90deg) translateZ(100px);
  animation: assembleBox 0.8s forwards;
}

@keyframes assembleBox {
  to {
    transform: rotateY(0) translateZ(0);
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 260px;
  height: 160px;
  margin-top:9rem;
}
