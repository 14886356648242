@import "../styles/main.scss";

/*
.mainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
*/

.zplayer {
  display: flex;
  flex: 1;
  flex-direction: column;

  //JMR styling for webkit, use with absolute player-controls styling
  // display: -webkit-flex;
  // display: flex;
  // -webkit-flex-direction: column;
  // flex-direction: column;

  @media only screen and (min-width: $bp-desktop) {
    height: 87vh;
  }

  &__subLayout {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media only screen and (min-width: $bp-tablet-portrait) {
      display: flex;
      flex-direction: row;
    }
  }

  &__subLeftLayout {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    /*@media only screen and (min-width: $bp-tablet-landscape) {
      max-width: 70vw;
    } */
    @media only screen and (min-width: $bp-tablet-portrait) {
      max-width: 70vw;
    }
  }

  &__player {
    position: relative;
    padding: 0 0.3rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__ytPlayer {
      flex: 1;

      @media only screen and (min-width: $bp-tablet-portrait) {
        height: 60vh;
      }
    }

    @media only screen and (min-width: $bp-tablet-portrait) {
      min-height: 70%;
    }
  }

  &__playerSubs {
    padding: 0 0.3rem;
    justify-content: center;
    align-items: center;
  }

  &__loop {
    align-items: flex-end;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 0px;

    &__row {
      flex: 1;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  
  }

  &__buttonSubTiles {
    overflow-y: auto;
    height: calc(100vh - 73vh);

    @media only screen and (min-width: $bp-tablet-portrait) {
      width: 30%;
      height: calc(92vh - 67px);
    }
    /*
    @media only screen and (max-width: $bp-tablet-portrait) {
      width: 30%;
      height: calc(92vh - 67px);
    } */

    @media only screen and (min-width: $bp-tablet-landscape) {
      height: calc(91vh - 69px);
    }

    /*
    @media only screen and (min-width: $bp-tablet-portrait) {
      width: 100% !important;
      height: calc(92vh - 69px);
    }*/
  }


  &__playerControls {
    align-items: flex-end;
    margin-bottom: 0.5rem;
//JMR works with webkit flex styling to always be at bottom, but then need additional styling to keep components on top from overlapping.
  //   position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  }
}

.subtileGrid {
  /* height: 250px; */
  overflow: hidden;
  /* flex-grow: 1; */
  width: 100vw;
}


.buttons-col {
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  align-self: flex-start;
}

//For Settings Icon
.no-margin-padding {
  --padding: 0;
  --margin: 0;
}

/* player tiles */
@media (min-width: 300px) {
  /* sm */
  .ion-row-sm {
    max-height: 290px !important;
  }

  .no-margin-padding {
    --padding-start: 5px;
    --padding-end: 5px;
    --margin: 0;
  }
}

@media (min-width: 768px) {
  /* md */
  .ion-row-md {
    max-height: 450px !important;
  }
}

@media (min-width: 992px) {
  /* lg:  max-height: 160px !important;*/
  .ion-row-lg {
    max-height: 550px !important;
  }
}

.select-label {
  // flex: none;
}

.bookmark-icon {
  position: absolute;
  top: 1px;
  right: -1px;
  font-size: 24px;
  color: #333;
  z-index: 9999;
}

.subeditor-icon {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 24px;
  color: #333;
  z-index: 9999;
}

.sub-copy-icon {
  position: absolute;
  bottom: 1px;
  right: 1px;
  font-size: 24px;
  color: #333;
  z-index: 9999;
}

.translate-ext-icon {
  position: absolute;
  bottom: 1px;
  left: 1px;
  font-size: 24px;
  color: #333;
  z-index: 9999;
}

.tile-views {
  position: absolute;
  bottom: 1px;
  right: -1px;
  font-size: 16px;
  color: #404070;
  z-index: 9999;
}

ion-badge {
  --background: rgb(108, 107, 123);
  --color: white;
  --padding-end: 1px;
  --padding-start: 1px;
}

/* The container for the slider */
.sliderContainer {
  display: flex;
  position: absolute;
  top: 0.5rem;
  left: 5%; /* centers the slider based on its 90% width */
  width: 85%;
  height: 5%; /* height of the slider relative to the video */
  z-index: 2;
  display: flex;
  align-items: center;
}

/* The actual slider */
.slider {
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%; /* this will make the slider fill its container's height */
  appearance: none; /* remove default appearance */
  outline: none;
  overflow: visible;
  cursor: pointer;
  position: relative;
  mix-blend-mode: difference;
  flex: 1 1 auto;
  flex-grow: 1; /* Allows it to take up the available space */
  margin: 0 10px; /* Space between the slider and the time values */
}

/* Styling the thumb of the slider for Chrome and Safari */
.slider::-webkit-slider-thumb {
  appearance: none;
  border: 1px solid red;
  background: darkred;
  box-shadow: 0px 0px 3px rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 25px; /* size of the round control */
  height: 25px;
  cursor: pointer;
  z-index: 5;
  opacity: 0.63;
}

/* Styling the thumb of the slider for Firefox */
.slider::-moz-range-thumb {
  appearance: none;
  background: white;
  border-radius: 50%;
  width: 25px; /* size of the round control */
  height: 25px;
  cursor: pointer;
}

.timeElapsed,
.timeRemaining {
  flex: 0 0 auto;
  align-self: flex-start;
  white-space: nowrap;
  color: rgb(254,215,49);
  z-index: 3; /* to ensure it's visible on top */
  padding: 2px, 5px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0, 0.5);
}

.timeTooltip {
  position: absolute;
  bottom: -45px; /* This places the tooltip just below the slider. Adjust as needed. */
  left: 0%;
  transform: translateX(-50%);
  display: none;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: orange;
  white-space: nowrap;
  font-size: 20px;
  z-index: 4;
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.video-player {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  opacity: 0.33;
  transition: opacity 0.2s;
}

.overlay-controls:hover {
  opacity: 1;
}

.overlay-controls ion-button {
  margin-right: 10px;
}

/* Add this to your styles.css or an equivalent file */
.scrollableArea {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-height: 400px; /* Set the maximum height according to your needs */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 #eee;
}

.scrollableArea::-webkit-scrollbar {
  width: 6px;
}

.scrollableArea::-webkit-scrollbar-track {
  background-color: #eee;
}

.scrollableArea::-webkit-scrollbar-thumb {
  background-color: #999;
}

.button-default {
  --background: rgb(91, 89, 145) !important;
}

.button-active {
  --background: rgb(36, 108, 251) !important;
}

.small-text {
  font-size: 0.8rem;
}

/* ion-accordion 
color: rgb(86,193,251);
color: rgb(213,184,128);
color: rgb(159,221,253);
*/
div[slot="content"] {
  background: rgba(var(--ion-color-rose-rgb), 0.25);
  color: rgb(213, 184, 128);
}

.ion-card-hoverable {
  cursor: pointer;
}

.ion-card-hoverable:hover {
  cursor: pointer;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
}

/*  Added for to handle ipad landscape  */
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "video"
    "control"
    "button";
}

.tablet-grid {
  grid-template-columns: 70% 30%;
  grid-template-rows: auto auto;
  grid-template-areas:
    "video button"
    "control button";
}

.videoContainerOLD {
  grid-area: video;
  position: absolute;
  width: 100%;
  height: 50%; /* Adjust this value if needed */
}

.videoContainer {
  grid-area: video;
  position: absolute;
  width: 100%;
  height: 500px; /* Adjust this value according to your needs */
}

.contentBelowVideo {
  height: 500px; /* This value should match the height of .videoContainer */
}

.tablet-video {
  height: 70%;
}

.control-container {
  grid-area: control;
}

.button-container {
  grid-area: button;
  height: 100%;
  overflow-y: auto;
}

.tablet-button {
  overflow-y: scroll;
}

//JMR added back in, remove after testing below the fold error
//For Settings Icon
// .no-margin-padding {
//   --padding-start: 0;
//   --padding-end: 0;
//   --padding-top: 0;
//   --padding-bottom: 10px;
//   --margin: 0;
//   --max-width: 20px !important;
// }


//Notes segment at top of page
.note-segment-button {
  margin: 0 !important;
  padding: 0 !important;
}

ion-textarea.custom-textarea {
  --background: #0d2136;
  --color: #d3e3fd;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  min-height: 20vh;
}

.custom-slate-editor {
  background: #0d2136;
  color: #d3e3fd;
  padding: 10px;
  min-height: 20vh;
}

.custom-slate-editor [contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #ddd;
  opacity: 0.8;
}

// Targeting the IonSelect popover for language selection
ion-popover.select-popover {
  --offset-z: 0px; // Your custom property
  min-height: 300px; // Setting minimum height
  max-height: 600px; // Optional: Setting maximum height
}

// target the content of the popover
ion-popover.select-popover .popover-content {
  min-height: 300px;
  max-height: 600px;

  // .popover-content,
  // .sc-ion-select-popover-md, /* For MD mode */
  // .sc-ion-select-popover-ios /* For iOS mode */ {
  //   min-height: 200px;
  //   max-height: 90vh;
  //   overflow-y: auto;
  // }
}

.shareModal {
  background: linear-gradient(#17193b, #333867);
  // max-width: 480px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: scroll;
  box-sizing: border-box;

  &__thumbnail {
    width: 100%;
    display: flex;
    justify-content: center;
    // padding: 10px;

    &__image {
      width: 100%;
      height: auto;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }
  }

  &__videoDetail {
    max-height: 150px;
    overflow-y: auto;
  }

  &__buttonGroup {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px;
  }

}

ion-modal#createSub-modal {
  --height: 31%;
  --border-radius: 8px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.createSub-container {
  &__input {
    display: inline-block;
    // margin-left: 1.3rem;
    font-size: 1.1rem;
    background-color: #333;
  }
  &__buttonGroup {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}

.custom-search-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
}

.custom-search-item .label-floating {
  padding-left: 5px;
  color: antiquewhite;
  font-size: 0.66em; /* Adjust the font size to about 66% of the original size */
  transform: translateY(-0.2rem); /* Adjust the vertical position */
}

.swap-button-container {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.swap-button {
  --border-radius: 50%;
}


.bottom-badge {
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  color: grey;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: .66rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}