.vcardCompact {
    .target-text {
      font-size: smaller;
    }
  }

  
  .large-subtitle-source {
    font-size: 1.68em;
  }

  .large-subtitle-target {
    font-size: 1.68em;
  }

  .language-section {
    margin-bottom: 10px;
  }
  
  .video-section {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .notes-section {
    margin-top: 10px;
  }
  
  .notes-text {
    margin: 0;
  }

  .earthy-gradient {
    // background: linear-gradient(to bottom right, #446d90, #20333f);
    // background: rgb(102,115,147);
    // background: linear-gradient(to bottom right, rgb(102,115,147), rgb(75, 73, 111));
    // background: linear-gradient(to bottom right, rgb(102,115,147), rgb(55, 58, 74));
    background: linear-gradient(to bottom right, rgb(81, 109, 156), rgb(102,115,147));
    
    color: #ffffff;
    border-radius: 10px;
    padding: 10px; 
  }

  // .video-section {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }
  
  .heart-icon {
    position: absolute;
    top: 10px; /* Adjust to align vertically as needed */
    right: 5px; /* 5px from the right border */
    z-index: 1; /* Ensures the heart icon is above other content */
  }