.dash-container ion-card {
	//--background: #80819f;
	//--background: #70718f;
	--background: linear-gradient(to bottom right, #9091af, #70718f);

	--color: #fcfeff;
	  --padding-top: 50px; // Increase top padding
  --padding-bottom: 50px; // Increase bottom padding
 
  
  }
  
  ion-card-title {
	//--color: #c0cbff;
	--color: #ffffff;
	//font-family: 'P0T-NOoDLE', sans-serif; // Apply the Amiga font

  }

//   ion-card-title.ios {
// font-size: 1em;
//   }
  
  ion-card-subtitle {
	--color: #fcfeff;
	
  }
  
//   /* iOS places the subtitle above the title */
//   ion-card-header.ios {
// 	display: flex;
// 	flex-flow: column-reverse;
//   }


  .avatar-column {
	color:#0059ff;
	display: flex;
	flex-direction: column;
	justify-content: center; // Centers the title vertically
	align-items: center; // Centers the title horizontally
	//background-color: #f0f0f0; // Example background color for the avatar column
	border-right: 1px solid #d0d0d0; // Adds a border between the columns

	ion-card-title {
	  font-size: 3rem;
	}
  }

  .datetime-center {
	display: flex;
	justify-content: center; // Centers the datetime picker horizontally
  }

  


  
//Date component
:root {
	--ion-color-rose: #831843;
	--ion-color-rose-rgb: 131, 24, 67;
	--ion-color-rose-contrast: #ffffff;
	--ion-color-rose-contrast-rgb: 255, 255, 255;
	--ion-color-rose-shade: #73153b;
	--ion-color-rose-tint: #8f2f56;
  
	--ion-text-color: #881337;
	--ion-text-color-rgb: 136, 19, 55;
  
	--ion-color-step-50: #f9e6e9;
	--ion-color-step-100: #f3dbdf;


	--ion-color-step-350: #d5a3b1;

	--ion-color-step-500: #c48295;
	--ion-color-step-550: #be778b;


	--ion-color-step-900: #94294a;
	--ion-color-step-950: #8e1e40;
  }
  
  .ion-color-datedark {
	--ion-color-base: var(--ion-color-rose);
	--ion-color-base-rgb: var(--ion-color-rose-rgb);
	--ion-color-contrast: var(--ion-color-rose-contrast);
	--ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
	--ion-color-shade: var(--ion-color-rose-shade);
	--ion-color-tint: var(--ion-color-rose-tint);
  }
  
  ion-datetime {
	--background: #645e87;
	--background-rgb: 255, 241, 242;
	--color:#393a4a;
  
	border-radius: 16px;
	box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;

  }

  .dashboard-page ion-item {
	--background: linear-gradient(to bottom right, #9091af, #70718f);
	--color: #d9d4ff;
  }
  
  /* Word icon refresh rotation animation */
@keyframes rotation {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(359deg);
	}
  }
  
  /* Class to apply the rotation */
  .rotating {
	animation: rotation 2s infinite linear;
  }

  .vocab-card {
	position: relative; /* Ensure the card is the positioning context */
  }
  
  .animation-overlay-card {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	overflow: hidden; /* Prevent internal overflow */
	padding: 0; /* Remove any padding */
	margin: 0; /* Remove any margin */
  }
  
  
  .animation-overlay-card .lottie {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain; /* Ensure the animation fits within its container */
  }
  
  

//   .datetime-center {
// 	ion-label {
// 	  display: block; // takes up its own line
// 	  text-align: center; //  center the label
// 	  margin-bottom: 10px; // 
	  
// 	}
//   }
  
  