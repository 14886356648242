@import "../../src/components/SplashScreen/SplashScreen.scss";

.home {
  line-height: 1.3rem;

  &__title {
    font-size: 1.1rem;
    font-weight: 500;
  }
}
.splash-text {
  opacity: 0;
  color: $text-color;
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: #fff 0.006em 0.006em 0.007em, #787878 1px 1px 1px,
    #787878 1px 2px 1px, #787878 1px 3px 1px, rgba(16, 16, 16, 0.4) 1px 18px 6px,
    rgba(16, 16, 16, 0.4) 1px 22px 10px, rgba(16, 16, 16, 0.4) 1px 26px 35px,
    rgba(16, 16, 16, 0.4) 1px 30px 65px, #fff -0.5em -0.1em 100px;
}

.categorySlider {
  margin-top: 5px;
  height: 26%;
  ion-slide {
      width: 33%;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 0px;
      justify-content: center;
      max-height: 200px; // Set a maximum height for the slides

      img {
          border-radius: 50%;
          max-height: 130px; // Control the height of images
          width: auto; // Maintain the aspect ratio
          display: block;
          margin: 0 auto; // Center the images
      }

      h3 { // Adjust this selector based on your HTML structure
        font-size: 0.8rem; // Adjust font size
        margin-top: 5px; // Adjust top margin
        text-align: center;
    }

  }
}

.videoSlider {
  margin-top: 5px;
  height: 26%;
  ion-slide {
      width: 33%;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 0px;
      justify-content: center;
      max-height: 200px; // Set a maximum height for the slides

      img {
          border-radius: 10%;
          max-height: 130px; // Control the height of images
          width: auto; // Maintain the aspect ratio
          display: block;
          margin: 0 auto; // Center the images
      }

      h3 { // Adjust this selector based on your HTML structure
        font-size: 0.8rem; // Adjust font size
        margin-top: 5px; // Adjust top margin
        text-align: center;
    }

  }
}



ion-toolbar {

  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}




.todo-content {
  display: flex;
  align-items: center;  /* Align checkbox and text vertically */
  justify-content: flex-start; /* Align items to the left within the container */
}

.todo-banner {
  background-color: rgb(115,153,201);
  padding: 10px;
  margin-bottom: 20px;
  text-align: center; 
}
// This targets the container directly within IonContent
.todo-list {
  padding: 15px;
}
