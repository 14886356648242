/* App.scss */
/*@media (min-width: 992px) {
  ion-split-pane {
    --side-min-width: 250px;
    --side-max-width: 250px;
  }

  ion-router-outlet {
    width: 100%;
  }
}
*/


/*  
  .hide-tab-bar .ion-tab-bar {
    display: none;
  }
*/
ion-fab-button {
  --border-radius: 15px;
  --box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --color: rgb(255, 255, 255);
  z-index: 9999;
}

ion-fab-button-play {
  width: 100px;
  --border-radius: 15px;
  --box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --color: rgb(255, 255, 255);
  z-index: 9999;
}

.tile-card {
  margin: 5px;
}

.compact-list-padding {
  padding: 2px; /* change this to the padding you want */
}

.custom-searchbar {
  /* --background: rgb(19,44,67); greenish blue */
  /* --background: #434558; */
  --background: #393b4e;
}

.custom-searchbar-local {
  /* --background: rgb(19,44,67); greenish blue */
  /* --background: #434558; */
  --background: #393b4e;
  padding-left: 10%;
  padding-right: 10%;
}

.custom-button-main {
  padding-left: 33%;
  padding-right: 33%;
  padding-bottom: 1%;
}

.hide-tab-bar {
  display: none;
}


@font-face {
  font-family: 'P0T-NOoDLE';
  src: url('/assets/fonts/Topaz_a500_v1.0.eot'); /* IE6-8 */
  src: url('/assets/fonts/Topaz_a500_v1.0.eot?#iefix') format('embedded-opentype'), /* IE9 */
       url("/assets/fonts/mO'sOul_v1.0.ttf") format('truetype'); /* Firefox, Chrome, Safari, Opera */
}
ion-toolbar {

  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}