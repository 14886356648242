@import "../../styles/main.scss";


$note-line-height: 1.4em;
$note-font-size: 1em;
$lines-of-text: 5; // Number of lines of text to accommodate


.card-with-notes {
  // Increase height based on the number of lines and line-height
  //height: calc(100% + #{$lines-of-text} * $note-line-height * $note-font-size);
  height: 24em;
  min-height: 24em; 
  overflow: hidden; // Or 'auto' for a scrollbar

  .note-text {
    max-height: calc(#{$lines-of-text} * $note-line-height * $note-font-size);
    overflow: auto; // To add a scrollbar if the text exceeds the allotted space
  }
}

.vcard {
  padding: 0px;
  margin: 5px 0;
  margin-bottom: 10px;
  overflow: none;
  position: relative;
  border: 1px solid #4a4a4a;

  // @media (min-width: 240px) {
  @media only screen and (min-width: $bp-tablet-portrait) {
    /* adjust this to fit 3 cards in the viewport height */
    height: calc(100vh / 2.4);
    /* enable scroll if content exceeds max-height */
    overflow: none;
    padding: 5px;
    position: relative;
  }
}

.vcard-header {
  padding: 5px;
}

/*
  .vcard-content {
    padding: 3px;
    padding-bottom: 5px;
    padding-top: 0px;
    font-size: 1em;
  }
*/

.vcard-content {
  position: relative;
  height: 100%;
  padding: 5px;
  background-color: #282828;
  font-size: 1em;
}

.vcard .vheart-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 2;
}

.vcard-title {
  // font-size: 1.2em;
  font-size: 1em;
  color: #478ffc;
}

.vstats {
  font-size: 0.85em;
  padding: 0;
  color: #dcdcdc;
}

.vdescription {
  color: #d3d3d3;
}

// Reusable styles
@mixin cardThumbnail {
  position: relative;
  padding-left: 0;
  border-radius: 5px;
  overflow: hidden;
}

@mixin videoLength {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
}

// Video Card Tablet style
.vcardTablet {
  --background: #212121;
  overflow: hidden;
  margin: 0.5rem 0;
  margin-left: -1.1rem;

  &__thumbnailContainer {
    @include cardThumbnail;
    width: 33%;

    div {
      @include videoLength;
    }
  }

  &__videoInfo {
    margin-left: 1.2rem;

    .vinfoItem {
      line-height: 1.6rem;
    }
  }
}

.vcardMobile {
  &__thumbnailContainer {
    @include cardThumbnail;

    div {
      @include videoLength;
    }
  }
}


.card {
  overflow: visible; 
  padding-bottom: 5px; 
  border: 1px solid #4a4a4a;
  position: relative;
  padding: 5px;
  color:rgb(210,209,158);
  background-color:rgb(17, 16, 33);
}

.card-note {
  // Set a minimum height for the cards w/ notes
  min-height: 26em; 
  overflow: visible; 
  padding-bottom: 5px; 
  border: 1px solid #4a4a4a;
  position: relative;
  padding: 5px;

  background-color:red;
}

.card-details {
  padding: 2px;
  box-sizing: border-box;
}

.card-header {
  padding: 5px;
}

.card-content {
  position: relative;
  //height: 100%;
  background-color: #071a2b;
  //color:rgb(210,209,158);
  //color: #1a73e8;
  display: flex;
  flex-direction: column;
  //overflow: hidden;


  ion-img {
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    width: 100%; // Full width
    height: auto;
  }
}

.card .heart-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 2;
}

// History video list
.vcardCompact {
  --background: #212121;
  margin: 0.5rem 0;
  margin-bottom: 5px;
  border-color: #b8860b;

  &__labels {
    > * {
      line-height: 1.3rem;
    }
    @media only screen and (max-width: $bp-mobile-large) {
      margin-top: 0.3rem;
    }
  }
}

.heart-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

//new fav heart placement, right side, middle
.image-wrapper {
  position: relative;
  display: block;

  ion-img {
    width: 100%;
    height: auto;
  }

  .vheart-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(50%); // Adjust to have the heart icon overlap the bottom of the image
    z-index: 2; // Ensure it's above the image
      }
}


/* VideoCardCompact */
.video-thumb {
  width: 100%;
  height: auto;
  object-fit: cover; /* this will make sure that the aspect ratio is maintained */
}
ß
.title {
  font-size: 1em;
  font-weight: 400;
}

.subtitle {
  font-size: 1em;
  padding: 0;
  color: #b8860b;
}

.vsubtitle {
  // margin-top: ;
  font-size: 1em !important;
  font-weight: 600 !important;
  padding: 0;
  color: #b8860b;
}

.description {
  font-size: 1em;
  color: lightgrey;
  padding-inline: 3px;
  border-bottom-style:groove;
  border-bottom: #b8860b;
}

ion-thumbnail {
  --size: 4em;
  --border-radius: 3px;
  margin-inline: 0 1rem;
}

.title,
.thumbnail {
  color: var(--ion-color-primary);
  cursor: pointer;
}

@media (max-width: 768px) {
  .title {
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }

  .vsubtitle {
    font-size: 1em !important;
    font-weight: 400 !important;
  }

  .description {
    font-size: 0.8em !important;
    color: #d3d3d3;
  }
}

@media (min-width: 768px) {
  .card {
    height: calc(100vh / 4);
    /* adjust this to fit 3 cards in the viewport height */
    overflow: none;
    /* enable scroll if content exceeds max-height */
    padding: 0;
    position: relative;

    .title {
      font-size: 1.1em !important;
      font-weight: 400 !important;
    }
    .subtitle {
      margin-top: 5px;
      font-size: .9em !important;
      font-weight: 400 !important;
    }
    .subtitle2 {
      font-size: .8em !important;
      font-weight: 400 !important;
      color: #fff;
    }
  
    .description {
      font-size: 0.8em !important;
      color: #d3d3d3;
    }
  }
}

@media (min-width: 915px) {
  .card {
    height: calc(100vh / 3);
    /* adjust this to fit 2 cards in the viewport height */
    overflow: none;
    /* enable scroll if content exceeds max-height */
    padding: 0;
    position: relative;
  }
}

.videoList {
  margin-top: -0.5rem;
  background-color: transparent;
}

//used by VideoCardGrid
.note-text {
  //background-color:#302d43;
  color: rgb(138,180,248);
  padding:3px;
  max-height: 8em; // Adjust as needed
  height: 5em;
  overflow-y: auto;
  text-overflow: ellipsis;
  // Add a scrollbar only when necessary
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: darken(#252526, 10%);
    border-radius: 2px;
  }
}

.notes-text-compact {
  display: -webkit-box;
  -webkit-line-clamp: 2; // Limit to 2 lines
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 5em; //line height

}
