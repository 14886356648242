.explainer-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-wrapper {
  width: 100%;
  height: 90%;
  video {
    width: auto; /* video take its natural width, in relation when adjusting its height */
    height: 100%; /* This ensures the video fills the height */
    object-fit: cover; 
    position: relative; 
    left: 50%; /* center the video */
    top: 50%; 
    transform: translate(-50%, -50%); 
  }
}

.dot-indicators {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: lightgray;

    &.active {
      background-color: red;
    }
  }
}


/* iFRAMES:
.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  strong {
    font-size: 20px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}

// .container strong {
//   font-size: 20px;
//   line-height: 26px;
// }

// .container p {
//   font-size: 16px;
//   line-height: 22px;
//   color: #8c8c8c;
//   margin: 0;
// }

// .container a {
//   text-decoration: none;
// }
*/